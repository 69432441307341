import { Component, Vue, Prop } from 'vue-property-decorator'
import '@lottiefiles/lottie-player'

@Component({
  components: {},
})
export default class Animation extends Vue {
  @Prop({ required: false }) src!: string
  @Prop({ default: false, required: false }) loop!: boolean
  json_data = ''

  mounted() {
    this.importData()
  }

  get animationData() {
    return this.json_data
  }

  async importData() {
    const animationData = await import('@/' + this.src)
    this.json_data = JSON.stringify(animationData)
  }

  // @Watch('modalOpen')
  // onOpen(value: string, oldValue: string) {
  //     this.$emit("closeModal", this.modalOpen);
  // }
}
